const $                = require('jquery');
const React            = require('react');
const PropTypes        = React.PropTypes;
const LinkedStateMixin = require('react-addons-linked-state-mixin');
const classNames       = require('classnames');
const Vogue            = require('vogue').Components;
const TextField        = Vogue.TextField;
const config           = require('./config');
const qs               = require('querystring');

const queryString = qs.parse(window.location.search.slice(1));
const RUNNING_EXPERIMENTS = require('../shared/optimizely').RUNNING_EXPERIMENTS;


const Form = React.createClass({
  propTypes: {
    inline: PropTypes.string,
    buttonImageSrc: PropTypes.string,
    className: PropTypes.string,
  },

  mixins: [LinkedStateMixin],

  getInitialState() {
    return {};
  },

  handleClick() {
    this.startFlow();
  },

  handleKeyPress(e) {
    if (e.which === 13) {
      e.stopPropagation();
      e.preventDefault();
      this.startFlow();
    }
  },

  capitalizeFirstLetter(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  },

  validateEmail(email) {
    // from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len
    return re.test(email);
  },

  domainQualify(email) {
    const blacklistedTlds = [
      '.ac', '.ad', '.ae', '.af', '.ag', '.al', '.am', '.an', '.ao', '.aq', '.ar', '.as', '.at', '.au', '.aw', '.ax', '.az', '.ba', '.bb', '.bd', '.be', '.bf', '.bg', '.bh', '.bi', '.bj', '.bm', '.bn', '.bo', '.br', '.bs', '.bt', '.bv', '.bw', '.by', '.bz', '.ca', '.cc', '.cd', '.cf', '.cg', '.ch', '.ci', '.ck', '.cl', '.cm', '.cn', '.cr', '.cs', '.cu', '.cv', '.cw', '.cx', '.cy', '.cz', '.dd', '.de', '.dj', '.dk', '.dm', '.do', '.dz', '.ec', '.edu', '.ee', '.eg', '.eh', '.er', '.es', '.et', '.eu', '.fi', '.fj', '.fk', '.fm', '.fo', '.fr', '.ga', '.gb', '.gd', '.ge', '.gf', '.gg', '.gh', '.gi', '.gl', '.gm', '.gn', '.gp', '.gq', '.gr', '.gs', '.gt', '.gu', '.gw', '.gy', '.hk', '.hm', '.hn', '.hr', '.ht', '.hu', '.id', '.ie', '.il', '.im', '.in', '.iq', '.ir', '.is', '.it', '.je', '.jm', '.jo', '.jp', '.ke', '.kg', '.kh', '.ki', '.km', '.kn', '.kp', '.kr', '.kw', '.ky', '.kz', '.la', '.lb', '.lc', '.li', '.lk', '.lr', '.ls', '.lt', '.lu', '.lv', '.ma', '.mc', '.md', '.me', '.mg', '.mh', '.mk', '.ml', '.mm', '.mn', '.mo', '.mp', '.mq', '.mr', '.ms', '.mt', '.mu', '.mv', '.mw', '.mx', '.my', '.mz', '.na', '.nc', '.ne', '.nf', '.ng', '.ni', '.nl', '.no', '.np', '.nr', '.nu', '.nz', '.om', '.pa', '.pe', '.pf', '.pg', '.ph', '.pk', '.pl', '.pm', '.pn', '.pr', '.ps', '.pt', '.pw', '.py', '.qa', '.re', '.ro', '.rs', '.ru', '.rw', '.sa', '.sb', '.sc', '.sd', '.se', '.sg', '.sh', '.si', '.sj', '.sk', '.sl', '.sm', '.sn', '.so', '.sr', '.ss', '.st', '.su', '.sv', '.sx', '.sy', '.sz', '.tc', '.td', '.tf', '.tg', '.th', '.tj', '.tk', '.tl', '.tm', '.tn', '.to', '.tp', '.tr', '.tt', '.tv', '.tw', '.tz', '.ua', '.ug', '.uk', '.uy', '.uz', '.va', '.vc', '.ve', '.vg', '.vi', '.vn', '.vu', '.wf', '.ws', '.ye', '.yt', '.yu', '.za', '.zm', '.zw',  // eslint-disable-line max-len
    ];
    const blacklistedDomains = [
      'gmail.com', 'yahoo.com', 'hotmail.com', 'msn.com',
      'aol.com', 'comcast.com', 'comcast.net', 'verizon.com',
      'me.com', 'icloud.com', 'outlook.com',
    ];
    const domain = email.slice(email.lastIndexOf('@') + 1);
    const tld = domain.slice(domain.lastIndexOf('.'));

    return (
      (blacklistedTlds.indexOf(tld) === -1) &&
      (blacklistedDomains.indexOf(domain) === -1)
    );
  },

  startFlow() {
    let email;
    if (this.state.email) {
      email = this.state.email.trim();
    } else {
      email = '';
    }

    if (email === '') {
      $('.flash').removeClass('u-hidden').text('You must provide an email.');
      return;
    } else if (!this.validateEmail(email)) {
      $('.flash').removeClass('u-hidden').text('Email must be valid.');
      return;
    }

    const qualified = this.domainQualify(email);

    window.analytics.track('Submitted email');
    // send email to segment to initiate outreach if drop out of onboarding
    window.analytics.identify({
      email: email,
      dname: qualified,
    });
    const urlEncodedEmail = encodeURIComponent(email);

    window.setTimeout(function() {
      if (
        (
         RUNNING_EXPERIMENTS.domainQualify &&
         !qualified &&
         !queryString.utm_source
        ) || RUNNING_EXPERIMENTS.domainOverride
      ) {
        window.location = `/welcome?email=${urlEncodedEmail}`;
      } else {
        window.location = `${config.endoEndpoint}/start?email=${urlEncodedEmail}`;
      }
    }, 500);
  },

  render() {
    const buttonClasses = classNames(
      'SignupForm-button',
      'Button',
      'Button--primaryFilled',
      {
        'Button--inline': this.props.inline === 'true',
      }
    );

    const formClasses = classNames('row', 'SignupForm-form', 'bottom-xs', {
      'SignupForm--inline': this.props.inline === 'true',
      'SignupForm--stacked': this.props.inline !== 'true',
    });

    const divClasses = classNames('SignupForm-textfieldContainer', 'u-flexEnd', {
      'col-xs-12': !this.props.buttonImageSrc,
      'col-xs-10': this.props.buttonImageSrc,
      'col-md-7': this.props.buttonImageSrc,
      'u-noPadding': this.props.buttonImageSrc,
      'col-sm-8': this.props.inline === 'true',
      'col-sm-10': this.props.inline !== 'true',
      'col-md-8': this.props.inline !== 'true',
    });

    const buttonDivClasses = classNames('SignupForm-buttonDiv', {
      'col-xs-12': this.props.inline === 'true' && !this.props.buttonImageSrc,
      'col-xs-2': this.props.buttonImageSrc,
      'col-sm-4': this.props.inline === 'true' && !this.props.buttonImageSrc,
      'col-xs-8': this.props.inline !== 'true',
      'col-sm-7': this.props.inline !== 'true',
      'col-md-6': this.props.inline !== 'true',
      'col-lg-5': this.props.inline !== 'true',
    });

    const cancelClasses = classNames({
      'u-isRemoved': this.props.inline === 'true',
      'col-xs-4': this.props.inline !== 'true',
    });

    const buttonContent = this.props.buttonImageSrc
      ? <img className="SignupForm-buttonImage" src={this.props.buttonImageSrc} alt="Get started" />
      : <button type="button" className={buttonClasses}>Start Free Trial</button>;

    return (
      <form className={formClasses}>
        <div className={divClasses}>
          <TextField
            className="SignupForm-email"
            label="Add work email to get started"
            valueLink={this.linkState('email')}
            onKeyPress={this.handleKeyPress}
          />
        </div>
        <div className={buttonDivClasses} onClick={this.handleClick}>
          {buttonContent}
        </div>
        <div className={cancelClasses}>
          <a href="#" className="Button Button--cancel Button--secondary">Cancel</a>
        </div>
      </form>
    );
  },
});

module.exports = Form;
