const optimizely = window.optimizely;
const ACTIVE_EXPERIMENTS = {
  domainQualify: 7584226920,
  domainOverride: 7604620674,
  formOverride: 7630981886,
};
const RUNNING_EXPERIMENTS = {};

if (optimizely && optimizely.data) {
  Object.keys(ACTIVE_EXPERIMENTS).forEach(function(experiment) {
    RUNNING_EXPERIMENTS[experiment] = optimizely.data.state.variationMap[
      ACTIVE_EXPERIMENTS[experiment]
    ] === 1;
  });
} else {
  Object.keys(ACTIVE_EXPERIMENTS).forEach(function(experiment) {
    RUNNING_EXPERIMENTS[experiment] = false;
  });
}

module.exports = {
  RUNNING_EXPERIMENTS,
  ACTIVE_EXPERIMENTS,
};
